import React, {useEffect, useRef} from 'react';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {DynamicStep, DynamicStepComponentProps} from '../../StepsManager/Components/DynamicStep';
import {StepHeader} from '../../StepsManager/Components/StepHeader';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {ContactAndAddressSummary} from '../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {usePaymentsApi} from '../../../WithPaymentsApi/WithPaymentsApi';
import {useBillingData, withBillingData} from './WithBillingData/WithBillingData';
import {PaymentWidgetWrapper} from './PaymentWidgetWrapper';
import {useExperiments, useFedopsLogger, usePanorama} from '@wix/yoshi-flow-editor';
import {ADD_NEW_ADDRESS_ID, FedopsInteractions, SPECS} from '../../../constants';
import {StepImplementationProps, StepState} from '../../../../../types/app.types';
import {BillingAddressTitle} from './BillingAddressTitle/BillingAddressTitle';
import {BillingDetails} from './BillingDetails/BillingDetails';
import {useMemberDetailsData, withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {CheckoutModel} from '../../../../../domain/models/Checkout.model';
import {DetailsFormButtons} from '../../StepsManager/Components/DetailsFormButtons/DetailsFormButtons';
import {useContinueButtonState} from '../../StepsManager/Components/DetailsFormButtons/UseContinueButtonState';
import {useFunctionResultObservation} from '@wix/function-result-observation';
import {classes as commonClasses} from '../../../common.st.css';

export enum PaymentStepDataHook {
  root = 'PaymentStep.root',
  header = 'PaymentStep.header',
  collapsed = 'PaymentStep.collapsed',
  open = 'PaymentStep.open',
  empty = 'PaymentStep.empty',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const InternalPaymentStep = ({index}: DynamicStepComponentProps) => {
  const {experiments} = useExperiments();
  const headerRef = useRef<HTMLDivElement>(null);
  const localeKeys = useLocaleKeys();
  const {paymentsApi, activePaymentId} = usePaymentsApi();
  const {withObservation} = useFunctionResultObservation();

  const {
    checkoutStore,
    formsStore: {areFormsLoaded},
    stepsManagerStore: {stepsList},
    memberStore: {isMember},
  } = useControllerProps();

  const {checkout, isShippingFlow, onInvalidDetailsFormSubmit} = checkoutStore;
  const fedops = useFedopsLogger();
  const panorama = usePanorama();
  const stepState = stepsList[index].state;
  const setPaymentAndBillingDetails = withObservation(checkoutStore, 'setPaymentAndBillingDetails');

  const {isFormValid, initForm, getBillingFormDataForSubmit, billingSameAsShipping, isShippingValidForBilling} =
    useBillingData();

  const {selectedAddressesServiceId, resetMemberDetailsState, isAddNewChecked, isSetAsDefaultChecked} =
    useMemberDetailsData();

  const {continueButtonState, isContinueButtonDisabled, setContinueButtonState, setIsContinueButtonDisabled} =
    useContinueButtonState();

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        void paymentsApi?.expand();
        setContinueButtonState(StatesButtonStates.IDLE);
        setIsContinueButtonDisabled(!areFormsLoaded || isContinueButtonDisabled);
        initForm();
        if (isMember) {
          resetMemberDetailsState();
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  const validateAndSubmit = async () => {
    setContinueButtonState(StatesButtonStates.IN_PROGRESS);
    setIsContinueButtonDisabled(true);
    const isBillingFormValid = await isFormValid();
    const setBillingSameAsShipping = isShippingFlow && billingSameAsShipping && isShippingValidForBilling;

    fedops.interactionStarted(FedopsInteractions.ValidatePaymentInteraction);
    if (experiments.enabled(SPECS.ShouldUsePanorama)) {
      panorama.transaction(FedopsInteractions.ValidatePaymentInteraction).start();
    }
    const {isValid: isPaymentValid} = (await paymentsApi?.validate()) ?? /* istanbul ignore next */ {};
    fedops.interactionEnded(FedopsInteractions.ValidatePaymentInteraction);
    if (experiments.enabled(SPECS.ShouldUsePanorama)) {
      panorama.transaction(FedopsInteractions.ValidatePaymentInteraction).finish();
    }

    if (!isPaymentValid || !isBillingFormValid) {
      onInvalidDetailsFormSubmit();
      setContinueButtonState(StatesButtonStates.IDLE);
      setIsContinueButtonDisabled(false);
      return;
    }

    await setPaymentAndBillingDetails({
      ...getBillingFormDataForSubmit(),
      addressesServiceId: isAddNewChecked ? ADD_NEW_ADDRESS_ID : selectedAddressesServiceId,
      setBillingSameAsShipping,
      setAsDefault: isSetAsDefaultChecked,
      activePaymentId,
    });
    setIsContinueButtonDisabled(false);
  };

  const scrollToHeader = () => {
    headerRef?.current?.scrollIntoView();
  };

  return (
    <>
      <StepHeader
        index={index}
        dataHook={PaymentStepDataHook.header}
        label={localeKeys.checkout.payment()}
        ref={headerRef}
      />
      <PaymentWidgetWrapper stepState={stepState} />
      {stepState === StepState.COLLAPSED && (
        <>
          <BillingAddressTitle />
          <ContactAndAddressSummary contact={checkout.billingInfo?.contact} address={checkout.billingInfo?.address} />
        </>
      )}
      {stepState === StepState.OPEN && (
        <>
          <BillingDetails />
          <DetailsFormButtons
            onCancel={scrollToHeader}
            onContinue={() => void validateAndSubmit()}
            continueButtonDisabled={isContinueButtonDisabled}
            continueButtonState={continueButtonState}
          />
        </>
      )}
    </>
  );
};

const WrappedInternalPaymentStep = withMemberDetailsData(
  withBillingData(InternalPaymentStep),
  (checkout: CheckoutModel) => checkout.billingInfo,
  'validateBillingAddress'
);

export const PaymentStep = ({index}: StepImplementationProps) => {
  const {experiments} = useExperiments();
  return (
    <DynamicStep
      index={index!}
      dataHook={PaymentStepDataHook.root}
      component={WrappedInternalPaymentStep}
      className={
        experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) ? commonClasses.oneColumnViewPadding : undefined
      }
    />
  );
};
